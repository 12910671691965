<template>
  <div class="review-bg">
    <Navbar />
    <div class="review-body">
      <TopBar />

      <div class="filter-bar boxarea">
        <!-- <div class="filter-box">
          <h2>제품 선택</h2>
          <select name="">
            <option value="">제품1</option>
            <option value="">제품2</option>
            <option value="">제품3</option>
          </select>
          <h2>기간</h2>
          <select name="" v-model="since">
            <option value="6" selected>지난 6개월</option>
            <option value="9">지난 9개월</option>
            <option value="12">지난 12개월</option>
          </select>
        </div>-->
        <SelectLayer />
      </div>
      <section class="review-insight1">
        <div class="boxarea">
          <h2>
            <img src="@/assets/images/reviewmind/chart_02.png" alt="아이콘" />
            {{ $t("level_satisfaction") }}
          </h2>
          <button @click="handleReviews">
            <i v-if="loading" class="el-icon-loading"></i>
            <i v-else class="el-icon-document"></i>
          </button>
          <p>{{ comma(ratingTotalCount) }} {{ $t("reviews") }}</p>
          <div class="nodata" v-if="sentiment_pred.length == 0">
            {{ $t("no_data") }}
          </div>
          <Semi-Donut v-else :series="sentiment_pred" :point="centerPoint" />
          <div class="confidence">
            <h2>{{ $t("review_credibility") }}</h2>
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="credibility"
            ></el-progress>
            <p>{{ getScore(credibility) }}</p>
          </div>
        </div>
        <div class="boxarea">
          <div class="rate-trend">
            <h2>
              <img src="@/assets/images/reviewmind/chart_02.png" alt="아이콘" />
              {{ $t("Reviews_StarTrends") }}
            </h2>
            <div class="page2-filter">
              <!-- <select name v-model="chartTerm">
                <option value="12" selected>{{ $t("last_12month") }}</option>
                <option value="9">{{ $t("last_9month") }}</option>
                <option value="6">{{ $t("last_6month") }}</option>
                <option value="3">{{ $t("last_3month") }}</option>
              </select>-->
              <select name v-model="isCredibility" @change="handleCredibility">
                <option value="all" selected>
                  {{ $t("review_credibility") }}
                </option>
                <option :value="true">{{ $t("credible") }}</option>
                <option :value="false">{{ $t("non_credible") }}</option>
              </select>
              <select name>
                <option value selected>{{ $t("review_source") }}</option>
                <option value>amazon</option>
                <option value></option>
              </select>
              <!-- <select name>
                <option value selected>리뷰 만족도</option>
                <option value></option>
                <option value></option>
              </select>
              <select name>
                <option value selected>리뷰 평점</option>
                <option value></option>
                <option value></option>
              </select>-->
            </div>
          </div>
          <!-- <LineColumn1 :series="reviewStatus" :labels="review_X" /> -->
          <div class="chartBox">
            <VueApexCharts
              :class="{ fullSize: index == 1 }"
              ref="chart"
              type="line"
              height="260"
              :options="chartOptions"
              :series="series"
            ></VueApexCharts>
          </div>
        </div>
      </section>
      <section class="review-insight2">
        <div class="boxarea r-insight-chart">
          <p>{{ productList[0].type == "naver" ? "Naver" : "Amazon" }}</p>
          <span>{{ comma(ratingTotalCount) }} {{ $t("reviews") }}</span>
          <div class="nodata" v-if="insightSentimentList.length == 0">
            {{ $t("no_data") }}
          </div>
          <Simple-Donut v-else :series="competitorA" :point="donutCenter" />
        </div>
        <div class="boxarea r-insight-chart">
          <p>Best Buy</p>
          <span>0 {{ $t("reviews") }}</span>
          <div class="nodata" v-if="competitorB.length == 0">
            {{ $t("no_data") }}
          </div>
          <Simple-Donut :series="competitorB" v-else />
        </div>
        <div class="boxarea r-insight-chart">
          <p>Wall Mart</p>
          <span>0 {{ $t("reviews") }}</span>
          <div class="nodata" v-if="competitorC.length == 0">
            {{ $t("no_data") }}
          </div>
          <Simple-Donut :series="competitorC" v-else />
        </div>
        <div class="boxarea r-insight-chart">
          <p>Costco</p>
          <span>0 {{ $t("reviews") }}</span>
          <div class="nodata" v-if="competitorD.length == 0">
            {{ $t("no_data") }}
          </div>
          <Simple-Donut :series="competitorD" v-else />
        </div>
      </section>
      <transition name="fade">
        <div class="dim" v-if="modalVisible">
          <ReviewDetail2
            @close="close"
            :reviewList="reviewList"
            :name="productName"
          />
          <div class="dimbg" @click="close"></div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { mapState } from "vuex";
import SemiDonut from "@/components/charts/SemiDonut";
import SimpleDonut from "@/components/charts/SimpleDonut2";
// import LineColumn1 from "@/components/charts/LineColumn";
import moment from "moment";
import Navbar from "@/components/Navbar";
import TopBar from "@/components/dashboard/TopBar";
import SelectLayer from "@/components/dashboard/SelectLayer";
import ReviewDetail2 from "@/components/pop/ReviewDetail2";
import {
  fetchSentimentPred,
  fetchReviewRating,
  fetchReviewInsight,
  fetchReviewStatus,
  fetchAsprectReviews,
} from "@/api/review";
import { fetchProductList } from "@/api/product";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  components: {
    SemiDonut,
    SimpleDonut,
    TopBar,
    Navbar,
    SelectLayer,
    VueApexCharts,
    ReviewDetail2,
  },
  data() {
    return {
      loading: false,
      modalVisible: false,
      reviewList: [],
      index: "1",
      source: "",
      moment: moment,
      isCredibility: "all",
      insightTotal: 0,
      donutCenter: 0,
      totalSum: 0,
      ratingSum: 0,
      centerPoint: 0,
      ratings: [],
      ratingTotalCount: 0,
      score: "",
      since: "", // default 기간 설정
      sentiment_pred: [],
      insightSentimentList: [],
      statusSentimentList: [],
      credibility: 0,
      productList: [55, 30, 20],
      competitorA: [],
      competitorB: [],
      competitorC: [],
      competitorD: [],
      chartTerm: "12",
      series: [],
      chartOptions: {
        colors: ["#E0EBFE", "#57E69C", "#6B93CC", "#D365A8"],

        chart: {
          id: "mychart",
          height: 350,
          type: "line",
          background: "#fafafa",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "30%",
          },
        },
        fill: {
          // colors: ["#E0EBFE"],
        },
        stroke: {
          curve: "smooth",
          width: [0, 3, 3, 3], // 그래프 두께 series배열 순서
          // colors: ["#57E69C", "#6B93CC", "#D365A8"],
        },
        // dataLabels: {
        //   enabled: false, // 바 그래프에 값 표시
        //   enabledOnSeries: [1, 2, 3],
        // },
        legend: {
          position: "right",
          markers: {
            // fillColors: ["#57E69C", "#6B93CC", "#D365A8"],
          },
        },

        xaxis: {
          type: "category",
          categories: [],
        },
        // yaxis: [
        //   {
        //     show: false
        //   },
        //   {
        //     show: false,
        //     seriesName: "리뷰개수", // y축 통합을 위해 시리즈네임 설정
        //     opposite: true
        //     //  title: {
        //     //   text: "Social Media",
        //     // },
        //   },
        //   {
        //     show: false,
        //     seriesName: "리뷰개수",
        //     opposite: true
        //   },
        //   {
        //     show: false,
        //     seriesName: "리뷰개수",
        //     opposite: true
        //   }
        // ]
      },
    };
  },
  created() {
    this.$store.dispatch("DASHBOARD_MENU", 2);
  },
  mounted() {
    this.getSentimentPred();
    this.getReviewInsight();
    this.getReviewStatus();
    this.getProductInfo();
  },
  computed: {
    ...mapState([
      "navVisible",
      "footerVisible",
      "productName",
      "term",
      "asin",
      "url",
      "type",
    ]),
  },
  watch: {
    asin: function () {
      this.getReviewStatus();
      this.getSentimentPred();
      this.getReviewInsight();
    },
    term: function () {
      this.getSentimentPred();
      this.getReviewInsight();
      this.getReviewStatus();
      this.isCredibility = "all";
      if (this.term == "") {
        this.index = 1;
      } else {
        this.index = 2;
      }
    },
  },
  methods: {
    close() {
      this.modalVisible = false;
    },
    handleReviews() {
      this.loading = true;
      let params = {
        productName: this.productName,
      };
      fetchAsprectReviews(params).then((res) => {
        if (res.data.status == 200) {
          this.reviewList = res.data.data;
          this.modalVisible = true;
        } else {
          return alert(res.data.message);
        }
        this.loading = false;
      });
    },
    handleCredibility() {
      this.posReviews = [];
      this.neuReviews = [];
      this.negReviews = [];
      if (this.isCredibility == "all") {
        this.getReviewStatus();
      } else {
        this.labels.forEach((item) => {
          let neg = 0;
          let neu = 0;
          let pos = 0;
          this.statusSentimentList.forEach((data) => {
            if (data._id.credibility == this.isCredibility)
              if (
                data._id.year + "-" + ("0" + data._id.month).substr(-2) ==
                item
              ) {
                if (data._id.pred == 0) {
                  neu = data.count;
                } else if (data._id.pred == 1) {
                  pos = data.count;
                } else if (data._id.pred == 2) {
                  neg = data.count;
                }
              }
          });
          this.neuReviews.push(neu);
          this.negReviews.push(neg);
          this.posReviews.push(pos);
        });

        this.$refs.chart.updateOptions(
          {
            series: [
              {
                name: "리뷰개수",
                type: "column",
                data: this.reviewsPerMonth,
              },
              {
                name: "Positive",
                type: "line",
                data: this.posReviews,
              },
              {
                name: "Neutral",
                type: "line",
                data: this.neuReviews,
              },
              {
                name: "Negative",
                type: "line",
                data: this.negReviews,
              },
            ],
          },
          true
        );
      }
    },
    // format(percentage) {
    //   if (percentage === 100) {
    //     this.score = "A+";
    //   } else {
    //     this.score = "C+";
    //   }
    //   return `${percentage}%`;
    // },
    getProductInfo() {
      fetchProductList().then((res) => {
        if (res.data.status == 200) {
          this.productList = res.data.data;
        } else {
          return alert(res.data.message);
        }
      });
    },
    sumArray(array) {
      let result = 0.0;

      for (let i = 0; i < array.length; i++) result += array[i];

      return result;
    },
    getSentimentPred() {
      let params = {
        asin: this.asin,
        term: this.term,
      };
      fetchReviewRating(params).then((res) => {
        if (res.status == 200) {
          this.ratingTotalCount = res.data.data.totalCount;
          this.ratings = res.data.data.rating;
          let data = [];
          for (let i = 5; i > 0; i--) {
            let input = 0;
            for (let j = 0; j < this.ratings.length; j++) {
              if (this.ratings[j]._id == i) {
                input = this.ratings[j].count;
              }
            }
            data.push(input);
          }
          this.totalSum =
            res.data.data.totalSum == 0 ? 100 : res.data.data.totalSum;
          this.ratingSum = this.sumArray(data) == 0 ? 1 : this.sumArray(data);
          this.centerPoint =
            (this.totalSum / this.ratingSum).toFixed(2) == 100
              ? ""
              : (this.totalSum / this.ratingSum).toFixed(2);
        }
      });
      fetchSentimentPred(params).then((res) => {
        if (res.status == 200) {
          this.sentiment_pred = res.data.data.sentiment;
          this.credibility = res.data.data.credibility;
        } else {
          return alert(res.data.message);
        }
      });
    },

    getReviewInsight() {
      let params = {
        asin: this.asin,
        term: this.term,
      };
      fetchReviewInsight(params).then((res) => {
        if (res.status == 200) {
          this.insightTotal = res.data.data.totalCount;
          this.insightSentimentList = res.data.data.sentimentList;
          this.ratings = res.data.data.rating;
          let arr = [];
          for (let i = 0; i < this.ratings.length; i++) {
            for (let j = 0; j < this.ratings[i].ratings.length; j++) {
              let a = {
                rate: this.ratings[i].ratings[j].rate,
                total: this.ratings[i].ratings[j].total,
              };
              arr.push(a);
            }
          }
          let pos;
          let neu;
          let neg;
          let sentimentArr = [];
          if (this.insightSentimentList.length != 0) {
            this.insightSentimentList.forEach((item) => {
              if (item._id.pred == 0) {
                neu = item.sum;
              } else if (item._id.pred == 1) {
                pos = item.sum;
              } else if (item._id.pred == 2) {
                neg = item.sum;
              }
            });
            pos = pos ? pos : 0;
            neu = neu ? neu : 0;
            neg = neg ? neg : 0;
            sentimentArr = [pos, neu, neg];
          }

          arr.sort((a, b) => {
            return b["rate"] - a["rate"];
          });
          let timeArr = 0;
          arr.forEach((item) => {
            timeArr += item.rate * item.total;
          });
          let sumArr = 0;
          arr.forEach((item) => {
            sumArr += item.total;
          });
          this.donutCenter = (timeArr / sumArr).toFixed(2);

          this.competitorA = sentimentArr;
        } else {
          return alert(res.data.message);
        }
      });
    },
    /**
     * +----------------+
     * | 리뷰 & 별점 트랜드 |
     * +----------------+
     */
    getReviewStatus() {
      let params = {
        asin: this.asin,
        term: this.term,
      };
      fetchReviewStatus(params).then((res) => {
        this.statusSentimentList = res.data.data.sentimentList;
        this.labels = res.data.data.labels;
        this.reviewsPerMonth = res.data.data.reviewsPerMonth;
        this.posReviews = res.data.data.posReviews;
        this.neuReviews = res.data.data.neuReviews;
        this.negReviews = res.data.data.negReviews;

        if (res.status == 200) {
          this.series = [
            {
              name: "리뷰개수",
              type: "column",
              data: this.reviewsPerMonth,
            },
            {
              name: "만족해요",
              type: "line",
              data: this.posReviews,
            },
            {
              name: "보통",
              type: "line",
              data: this.neuReviews,
            },
            {
              name: "별로예요",
              type: "line",
              data: this.negReviews,
            },
          ];

          this.$refs.chart.updateOptions(
            {
              xaxis: {
                categories: this.labels,
              },
            },
            true
          );
          // this.review_X = res.data.data.labels;
          // this.chartOptions.labels = res.data.data.labels;
        }
      });
    },
  },
  beforeDestroy() {
    this.$store.dispatch("SET_ASIN", "");
    this.$store.dispatch("SET_URL", "");
    this.$store.dispatch("VIEW_TERM", "");
  },
};
</script>
