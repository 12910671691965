<template>
  <div>
    <h2>{{ title }}</h2>
    <VueApexCharts
      ref="simpleDonut"
      type="donut"
      height="150"
      :options="chartOptions"
      :series="series"
    ></VueApexCharts>
    <span class="center-score">{{ point }}</span>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    VueApexCharts
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    series: {
      type: Array
    },
    point: {
      type: String
    }
  },
  data() {
    return {
      // series: [40, 20, 10],
      chartOptions: {
        labels: ["Positive", "Neutral", "Negative"],
        chart: {
          width: 380,
          type: "donut"
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "10px",
            fontWeight: "normal"
          },
          dropShadow: {
            enabled: false
          }
        },
        // responsive: [
        //   {
        //     breakpoint: 480,
        //     options: {
        //       chart: {
        //         width: 200,
        //       },
        //       legend: {
        //         show: false,
        //       },
        //     },
        //   },
        // ],
        fill: {
          colors: ["#48a2d8", "#6b93cc", "#d365a8"]
        },
        legend: {
          position: "right",
          offsetY: 100,
          height: 80,
          markers: {
            fillColors: ["#48A2D8", "#6B93CC", "#D365A8"]
          }
        },
        // chart: {
        //   type: "donut",
        // },
        // dataLabels: {
        //   enabled: true,
        //   style: {
        //     fontSize: "10px",
        //     fontWeight: "normal",
        //   },
        //   dropShadow: {
        //     enabled: false,
        //   },
        // },
        plotOptions: {
          pie: {
            donut: {
              size: "40%"
            }
          }
        }
        // legend: {
        //   position: "right",
        //   offsetY: "100px",
        // },
        // responsive: [
        //   {
        //     breakpoint: 480,
        //     options: {
        //       chart: {
        //         width: 200,
        //       },
        //       legend: {
        //         position: "bottom",
        //       },
        //     },
        //   },
        // ],
      }
    };
  }
};
</script>
